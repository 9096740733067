<template>
  <div class="divider-title">
    <div class="line"></div>
    <span>{{ text }}</span>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "DividerTitle",
  props: {
    text: {
      type: String,
      default: "NULL"
    }
  }
};
</script>
<style lang="less">
.divider-title {
  height: 24px;
  line-height: 24px;
  width: 100%;
  position: relative;
  .line {
    top: 50%;
    height: 2px; /*no*/
    background: #921d23;
    width: 100%;
    position: relative;
  }
  span {
    display: inline-block;
    position: relative;
    background: #fff;
    margin-left: 50px;
    width: 158px;
    text-align: center;
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
