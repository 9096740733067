/*
 * @Author: your name
 * @Date: 2020-06-11 12:54:13
 * @LastEditTime: 2020-06-15 09:08:20
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\service\collect.js
 */ 
import { get, post } from "../common/query";

export const EDIT_COLLECT = data => post("/pcollect/collect", data);
export const GET_COLLECT = data => get("/pcollect/myCollect", data);
