<template>
  <div class="collect">
    <div class="header">
      <divider-title text="我的收藏" />
      <div class="no-data-label" v-show="!collectList.length">您还没有收藏</div>
    </div>
    <div class="no-data-container">
      <div class="no-data" v-show="!collectList.length">
        <div class="go-irisgem" @click="$router.push('/production.html')">
          <span>
            访问伊莉丝钻石官网
            <i class="el-icon-caret-right"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="container" v-show="collectList.length">
      <div class="list">
        <div class="ware-item" v-for="(item, index) in collectList" :key="index" @click="tzlast(item.id)">
          <div class="imgs"><img :src="item.pic.picUrl" alt /></div>
          <div class="ware-info">
            <span class="warename">{{ item.title }}</span>
            <span class="desc">{{ item.desc }}</span>
            <span class="price">{{ item.maxpriceStr }}</span>
          </div>
          <div class="del-icon" @click.stop="deleteCollect(item, index)">
            <i class="el-icon-circle-close"></i>
          </div>
        </div>
        <div class="pagenation">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            hide-on-single-page
            :page-size="searchData.size"
            @current-change="pageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import dividerTitle from "@/components/dividerTitle";
import { EDIT_COLLECT, GET_COLLECT } from "../../service/collect";
export default {
  name: "Collect",
  components: { dividerTitle },
  data() {
    return {
      collectList: [],
      searchData: {
        start: 0,
        size: 5
      },
      total: 0
    };
  },
  mounted() {
    this.getCollect();
  },
  methods: {
    tzlast(id){
       this.$router.push({
        path: "/productInfo.html",
        query: {
          id: id
        }
      });
    },
    getCollect() {
      GET_COLLECT({ ...this.searchData }).then(res => {
        this.total = res.attachment.total;
        this.collectList = res.attachment.datas;
        console.log(res);
      });
    },
    deleteCollect(item, index) {
      let param = {
        type: 2,
        productid: item.id
      };
        let r =confirm('确定删除吗？删除后不可恢复')
        if(r){
          EDIT_COLLECT(param).then(res => {
        if (res.status === 200) {
          this.$message({
            type: "success",
            message: "删除成功"
          }); 
            this.collectList.splice(index, 1);
          }
        });
      }
    },
    pageChange(val) {
      console.log(val)
      this.searchData.start = (val - 1) * this.searchData.size;
      this.getCollect();
       document.getElementById("sapp").scrollTop = 0
    }
  }
};
</script>
<style lang="less" scoped>
.collect {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  padding-bottom: 86px;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
    .no-data-label {
      margin-left: 92px;
      font-size: 13px;
      color: #666;
      line-height: 20px;
      padding-top: 12px;
    }
  }
  .no-data {
    height: 420px;
    padding: 35px 72px 0;
    margin: 0 20px;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .go-irisgem {
      height: 82px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      > span {
        padding: 0 20px;
        // width: 130px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 13px;
        color: #666;
        border: 1px solid #666; /*no*/
        cursor: pointer;
        i {
          font-size: 16px;
          color: #901d22;
        }
      }
    }
  }

  .container {
    padding: 20px 20px 0;
  }
  .list {
    padding: 15px 20px 0;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .ware-item {
      width: 100%;
      // height: 140px;
      padding: 30px 36px 30px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #cfcfcf; /*no*/
      &:hover{
        cursor: pointer;
      }
      .imgs{
        width: 80px;
        height: 80px;
        min-width: 56;
        min-height: 56;
        border-radius: 8px;
        margin-right: 40px;
        overflow: hidden;
        img {
         width: 100%;
         height: 100%;
         object-fit: cover;
        }
      }
     
      .ware-info {
        flex: 1;
        // height: 80px;
        display: flex;
        flex-direction: column;
        > span {
          font-size: 12px;
          line-height: 2;
        }
        .desc {
          color: #676767;
        }
        .price {
          color: #951c25;
        }
        .warename {
          font-size: 14px;
          color: #000;
        }
      }
      .del-icon {
        width: 260px;
        text-align: center;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  .pagenation {
    background: #fff;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
